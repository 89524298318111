/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Grid,
  Popover,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  IconButton,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import {
  DivNew,
  DivUpdate,
  DivDelete,
  TabNew,
  TabUpdate,
  TabDelete,
  ContactNew,
  ContactUpdate,
  ContactDelete,
} from '../sections/@dashboard/division';
// api
import API from '../api';

// ----------------------------------------------------------------------

export default function DivPage() {
  const [open, setOpen] = useState(null);
  const [openSub, setOpenSub] = useState(null);
  const [openContact, setOpenContact] = useState(null);

  const [selectedDiv, setSelectedDiv] = useState(null);
  const [selectedTab, setSelecTab] = useState(null);
  const [selectedContact, setSelecContact] = useState(null);

  const [divs, setDivs] = useState([]);
  const [tabs, setSubTabs] = useState([]);
  const [divisionUsers, setDivisionUsers] = useState([]);

  const [contact, setContact] = useState([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const fetchDivs = async ({ paramPage = page, paramLimit = rowsPerPage }) => {
    const response = await API.get(`/division?page=${paramPage}&limit=${paramLimit}`);
    const {
      data: { data, success, pagination },
    } = response;
    if (success === 1) {
      // console.log(pagination);
      setDivs(data);
      setTotal(pagination.total);
    }
  };

  const fetchTabsByDivId = async (id) => {
    const response = await API.get(`/division/${id}`);
    const {
      data: { data },
    } = response;
    setSubTabs(data);
  };

  const fetchContactByDivId = async (id) => {
    const response = await API.get(`/contact?id=${id}`);
    const {
      data: { data },
    } = response;
    setContact(data);
    console.log(data);
  };
  const fetchDivisionUsers = async () => {
    const response = await API.get(`/division/user`);
    const {
      data: { data },
    } = response;
    setDivisionUsers(data);
  };

  useEffect(() => {
    try {
      fetchDivs({});
      fetchDivisionUsers();
    } catch (error) {
      console.warn(error.message);
    }
  }, []);

  useEffect(() => {
    fetchDivs({ page, limit: rowsPerPage });
    if (selectedDiv?.d_id) fetchTabsByDivId(selectedDiv.d_id);
    if (selectedDiv?.d_id) fetchContactByDivId(selectedDiv.d_id);
  }, [page, rowsPerPage]);

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenSub(null);
    setOpenContact(null);
  };

  const handleOpenDiv = (event, data) => {
    setSelectedDiv(data);
    setOpen(event.currentTarget);
  };

  const handleOpenTab = (event, data) => {
    setSelecTab(data);
    setOpenSub(event.currentTarget);
  };

  const handleOpenContact = (event, data) => {
    setSelecContact(data);
    setOpenContact(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetAll = () => {
    handleCloseMenu();
    fetchDivisionUsers();
    setPage(1);
    fetchDivs({});
    if (selectedDiv?.d_id) fetchTabsByDivId(selectedDiv.d_id);
    if (selectedDiv?.d_id) fetchContactByDivId(selectedDiv.d_id);
  };

  return (
    <>
      <Helmet>
        <title> Харьяа Хэлтэс </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Харьяа Хэлтэс
          </Typography>
        </Stack>

        <Scrollbar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Харьяа Хэлтэс</TableCell>
                        {/* <TableCell>Хэрэглэгч</TableCell> */}
                        <TableCell align="right">
                          <DivNew resetAll={resetAll} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {divs.length > 0 ? (
                        divs.map((rowData) => {
                          const { d_id, d_name, a_username } = rowData;
                          return (
                            <TableRow
                              key={d_id}
                              hover
                              onClick={() => {
                                fetchTabsByDivId(d_id);
                                fetchContactByDivId(d_id);
                                setSelectedDiv(rowData);
                              }}
                            >
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {d_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {a_username || '---'}
                                  </Typography>
                                </Stack>
                              </TableCell> */}
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenDiv(event, rowData)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Өгөгдөл олдсонгүй
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ангилал</TableCell>
                        <TableCell align="right">
                          {!!selectedDiv && <TabNew resetAll={resetAll} id={selectedDiv.d_id} />}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tabs.length > 0 ? (
                        tabs.map((rowData) => {
                          const { tab_id, tab_name, tab_detail } = rowData;
                          return (
                            <TableRow key={tab_id} hover>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {tab_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenTab(event, rowData)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                {selectedDiv ? 'Өгөгдөл олдсонгүй' : 'Харьяа хэлтэс сонгоно уу'}
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
              <Typography variant="subtitle2" noWrap px={2} py={4}>
                Холбоо барих
              </Typography>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Утас</TableCell>
                        <TableCell>и-мэйл</TableCell>
                        <TableCell>Хаяг</TableCell>
                        <TableCell align="right">
                          {!!selectedDiv && <ContactNew resetAll={resetAll} id={selectedDiv.d_id} />}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {contact.length > 0 ? (
                        contact.map((rowData) => {
                          const { address, email, locationSrc, phone_number } = rowData;
                          return (
                            <TableRow key={phone_number} hover>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {phone_number}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {email}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {address}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell width={'full'} align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenContact(event, rowData)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                {selectedDiv ? 'Өгөгдөл олдсонгүй' : 'Харьяа хэлтэс сонгоно уу'}
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <DivUpdate division={selectedDiv} resetAll={resetAll} divisionUsers={divisionUsers} />
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <DivDelete id={selectedDiv?.d_id} resetAll={resetAll} />
        </MenuItem>
      </Popover>

      <Popover
        open={Boolean(openSub)}
        anchorEl={openSub}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <TabUpdate tab={selectedTab} resetAll={resetAll} />
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <TabDelete id={selectedTab?.tab_id} resetAll={resetAll} />
        </MenuItem>
      </Popover>
      <Popover
        open={Boolean(openContact)}
        anchorEl={openContact}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <ContactUpdate division={selectedContact} resetAll={resetAll} />
        </MenuItem>
      </Popover>
    </>
  );
}
