/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Avatar,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { NewsListHead, MshiidelModal } from '../sections/@dashboard/createdby';
// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'index', label: '№', alignRight: false },
  // { id: 'description', label: 'Дэд гарчиг', alignRight: false },
  // { id: 'createdAt', label: 'Бүртгэсэн огноо', alignRight: false },
  { id: 'posted_date', label: 'Зураг', alignRight: false },
  { id: 'project', label: 'Project', alignRight: false },
  { id: 'title', label: 'Гарчиг', alignRight: false },
  { id: 'txt', label: 'Дэлгэрэнгүй', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function CreatedByPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [news, setNews] = useState([]);

  const [selectedNews, setSelectedNews] = useState({});

  const [totalNewsCount, setTotalNewsCount] = useState(0);

  const [catID, setCatID] = useState(null);
  const [subCatID, setSubCatID] = useState(null);

  const [payload, setPayload] = useState(null);

  const fetchNews = async ({ page = 1, limit = 20, payload }) => {
    setLoading(true);
    const response = await API.get(`/v2/createdby?page=${page}&limit=${limit}`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setNews(data);
    setTotalNewsCount(total);
    setLoading(false);

    console.log(data);
  };

  const filterSearch = async (payload) => {
    setPayload(payload);
    const { selectedCategory, page = 1, limit = rowsPerPage } = payload;
    const response = await API.get(
      `/v2/brand?page=${page}&limit=${limit}${selectedCategory ? `&cat_id=${selectedCategory?._id}` : ''}`
    );
    const {
      data: {
        data,
        success,
        pagination: { total },
      },
    } = response;
    if (success) {
      setNews(data);
      setTotalNewsCount(total);
    }
  };

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const response = await API.get('/v2/category');
    const {
      data: { data, success },
    } = response;
    if (success) setCategories(data);
  };

  const [brands, setBrands] = useState([]);

  const fetchBrands = async () => {
    const response = await API.get('/v2/brand');
    const {
      data: { data, success },
    } = response;
    if (success) setBrands(data);
  };

  const resetPage = () => {
    setPage(0);
    setPayload(null);
    setSelectedNews({});
    setOpen(false);
    try {
      setLoading(true);
      fetchNews({ page, limit: rowsPerPage });
      fetchCategories();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (payload) {
        filterSearch({ ...payload, page: page + 1, limit: rowsPerPage });
      } else {
        fetchNews({ payload, page: page + 1, limit: rowsPerPage });
      }
      fetchCategories();
      fetchBrands();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDelete = async () => {
    // todo: call api
    try {
      const response = await API.delete(`/createdby/${selectedNews._id}`);
      const {
        data: { success },
      } = response;

      if (success) {
        handleCloseMenu();
        fetchNews(page, rowsPerPage);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOpenMenu = (event, selectedNews) => {
    setOpen(event.currentTarget);
    setSelectedNews(selectedNews);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Helmet>
        <title> Created By </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Created By / Ажлын гүйцэтгэл
          </Typography>

          <MshiidelModal resetPage={resetPage} brands={brands} categories={categories} />
        </Stack>

        <Card>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <NewsListHead headLabel={TABLE_HEAD} rowCount={news.length} />
                  <TableBody>
                    {news.length > 0 ? (
                      news.map((row, index) => {
                        const { img, project, title, txt } = row;
                        return (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle" noWrap>
                                  <Avatar
                                    sx={{ bgcolor: '#ebebeb' }}
                                    alt="img"
                                    src={`${API_URL}${img}`}
                                    variant="square"
                                  />
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {project|| '--'}
                                </Typography>
                              </Stack>
                            </TableCell>{' '}
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {title|| '--'}
                                </Typography>
                              </Stack>
                            </TableCell>{' '}
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" maxWidth={500} alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {txt || '--'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Өгөгдөл олдсонгүй
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalNewsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem>
          <MshiidelUpdate news={selectedNews} resetPage={resetPage} categories={categories} brands={brands} />
        </MenuItem> */}

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Устгах
        </MenuItem>
      </Popover>
    </>
  );
}
