// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Самбар',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Brand',
    path: '/dashboard/brand',
    icon: icon('ic_blog'),
  },
  {
    title: 'Ангилал',
    path: '/dashboard/categories',
    icon: icon('ic_blog'),
  },
  {
    title: 'Бүтээгдэхүүн',
    path: '/dashboard/product',
    icon: icon('ic_lock'),
  },
  {
    title: 'Баннер',
    path: '/dashboard/banner',
    icon: icon('ic_lock'),
  },
  {
    title: 'Зах зээлийн үнэ',
    path: '/dashboard/price',
    icon: icon('ic_lock'),
  },
  {
    title: 'М-Шийдэл',
    path: '/dashboard/mshiidel',
    icon: icon('ic_lock'),
  },
  {
    title: 'Created By',
    path: '/dashboard/createdby',
    icon: icon('ic_lock'),
  },
  {
    title: 'Mr Misheel',
    path: '/dashboard/mrmisheel',
    icon: icon('ic_lock'),
  },
  {
    title: 'Co Leader',
    path: '/dashboard/coleader',
    icon: icon('ic_lock'),
  },
  {
    title: 'Бидний тухай',
    path: '/dashboard/about',
    icon: icon('ic_user'),
  },
  {
    title: 'Хэрэглэгч',
    path: '/dashboard/user',
    icon: icon('ic_analytics'),
  },
];

export default navConfig;
