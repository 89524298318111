import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Popover,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  IconButton,
  CircularProgress,
  Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { FeedbackCreate, FeedbackUpdate, FeedbackDelete } from '../sections/@dashboard/feedback';
// api
import API from '../api';

// ----------------------------------------------------------------------

export default function FaqPage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedFeedback, setSelectedFeedback] = useState({});

  const [feedbacks, setFeedbacks] = useState([]);

  const fetchFeedbacks = async () => {
    const response = await API.post('/faq/question', { key_name: '' });
    const {
      data: { data },
    } = response;
    // todo: remove console log, and set pagination
    setFeedbacks(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchFeedbacks();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedFeedback(data);
    setOpen(event.currentTarget);
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedFeedback({});
    fetchFeedbacks();
  };

  return (
    <>
      <Helmet>
        <title>Асуулт Хариулт</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Асуулт Хариулт
          </Typography>
          <FeedbackCreate resetPage={resetPage} />
        </Stack>

        <Scrollbar>
          <Card>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 400 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Асуулт</TableCell>
                    <TableCell>Хариулт</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbacks.length > 0 ? (
                    feedbacks.map((rowData, index) => {
                      const { f_id, f_question, f_answer } = rowData;
                      return (
                        <TableRow key={f_id} hover>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap px={2}>
                                {index + 1}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle" noWrap px={2}>
                                {f_question}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle" noWrap>
                                {f_answer.slice(0, 75)}...
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, rowData)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Өгөгдөл олдсонгүй
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <FeedbackUpdate selectedFeedback={selectedFeedback} resetPage={resetPage} />
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <FeedbackDelete id={selectedFeedback.f_id} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
