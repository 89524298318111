/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Grid,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { ImageSelector } from './NewsModal';
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsUpdate({ news, resetPage, categories }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [brandDetailDesc, setBrandDetailDesc] = useState('');
  const [brandDetailEmail, setBrandDetailEmail] = useState('');
  const [brandDetailFacebook, setBrandDetailFacebook] = useState('');
  const [catName, setCatName] = useState('');
  const [brandDetailNumber, setBrandDetailNumber] = useState('');
  const [brandName, setBrandName] = useState('');
  const [web_url, setWeb_url] = useState('');
  const [selectedCategory, setsetselectedCategory] = useState('');

  const [brandDetailCoverImg, setBrandDetailCoverImg] = useState([]);
  const [brandLogo, setBrandLogo] = useState([]);
  const [paths, setPaths] = useState([]);
  const [logoPaths, setLogoPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageSelect, setOpenImageSelect] = useState(false);
  useEffect(() => {
    const {
      _id,
      brandDetailCoverImg,
      brandDetailDesc,
      brandDetailEmail,
      brandDetailFacebook,
      brandDetailNumber,
      brandLogo,
      brandName,
      web_url,
    } = news;

    setBrandDetailDesc(brandDetailDesc);
    setBrandDetailEmail(brandDetailEmail);
    setBrandDetailFacebook(brandDetailFacebook);
    setBrandDetailNumber(brandDetailNumber);
    setBrandName(brandName);
    setWeb_url(web_url);
    setsetselectedCategory(news.categoryId?._id); // Accessing _id safely

    if (brandLogo) {
      setBrandLogo([`${brandLogo}`]); // Setting as an array of URLs
      console.log(`${API_URL}${brandLogo}`);
    } else {
      setBrandLogo([]); // Ensure it's an array even if no logo is present
    }

    if (brandDetailCoverImg) {
      setBrandDetailCoverImg([`${brandDetailCoverImg}`]); // Setting as an array of URLs
    } else {
      setBrandDetailCoverImg([]); // Ensure it's an array even if no cover image is present
    }

    console.log(news);

    // You might want to add `news.categoryId` to the dependency array if it can change
    // Ensure `categories` is also included if it's used inside this effect
  }, [news, categories]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });
  const {
    acceptedFiles: acceptedLogoFiles,
    getRootProps: getLogoRootProps,
    getInputProps: getLogoInputProps,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });
  const handleCoverDrop = (acceptedFiles) => {
    setBrandDetailCoverImg(acceptedFiles);
  };

  const handleLogoDrop = (acceptedFiles) => {
    setBrandLogo(acceptedFiles);
  };
  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const logoFiles = acceptedLogoFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const PDFFiles = acceptedLogoFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBrandDetailDesc('');
    setBrandDetailEmail('');
    setBrandDetailFacebook('');
    setBrandDetailNumber('');
    setBrandName('');
    setWeb_url('');
    setsetselectedCategory(''); // Accessing _id safely

  };

  const coverDropzone = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: handleCoverDrop,
  });

  const logoDropzone = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: handleLogoDrop,
  });

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();
    console.log(brandDetailCoverImg)
    newsData.append('brandDetailCoverImg', brandDetailCoverImg[0]);
    newsData.append('brandDetailDesc', brandDetailDesc);
    newsData.append('brandDetailEmail', brandDetailEmail);
    newsData.append('brandDetailNumber', brandDetailNumber);
    newsData.append('brandDetailFacebook', brandDetailFacebook);
    newsData.append('categoryId', selectedCategory);
    newsData.append('brandLogo', brandLogo[0]);
    newsData.append('web_url', web_url);

    try {
      setLoading(true);
      const response = await API.put(`/v2/brand/${news._id}`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSelectImage = (data) => {
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };
  const handlePickImage = () => {
    setOpenImageSelect(true);
  };
  const handleDeleteCover = (index) => {
    const updatedCoverImg = [...brandDetailCoverImg];
    updatedCoverImg.splice(index, 1);
    setBrandDetailCoverImg(updatedCoverImg);
  };
  const handleDeleteLogo = (index) => {
    const updatedLogo = [...brandLogo];
    updatedLogo.splice(index, 1);
    setBrandLogo(updatedLogo);
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Brand засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={brandName}
                onChange={(event) => setBrandName(event.target.value)}
                label="Нэр"
                type="text"
                fullWidth
                autoFocus
              />
              <FormControl fullWidth sx={{ width: 1, my: 1 }}>
                <InputLabel id="category-box-label">Категори сонгох</InputLabel>
                <Select
                  labelId="category-box-label"
                  id="category-box"
                  defaultValue={selectedCategory}
                  label="Категори сонгох"
                >
                  {categories.map((option, index) => (
                    <MenuItem
                      onClick={(event) => {
                        setsetselectedCategory(option._id);
                      }}
                      key={index}
                      value={option._id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={brandDetailNumber}
                onChange={(event) => setBrandDetailNumber(event.target.value)}
                label="Утасны дугаар:"
                type="text"
                fullWidth
                autoFocus
              />{' '}
              <TextField
                sx={{
                  mb: 1,
                }}
                value={brandDetailEmail}
                onChange={(event) => setBrandDetailEmail(event.target.value)}
                label="Имэйл хаяг:"
                type="text"
                fullWidth
                autoFocus
              />{' '}
              <TextField
                sx={{
                  mb: 1,
                }}
                value={brandDetailFacebook}
                onChange={(event) => setBrandDetailFacebook(event.target.value)}
                label="Фэйсбүүк:"
                type="text"
                fullWidth
                autoFocus
              />{' '}
              <TextField
                sx={{
                  mb: 1,
                }}
                value={web_url}
                onChange={(event) => setWeb_url(event.target.value)}
                label="Вэбсайт URL:"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={brandDetailDesc}
                onChange={(event) => setBrandDetailDesc(event.target.value)}
                label="Тайлбар:"
                type="text"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Dialog open={openImageSelect} fullWidth maxWidth={false}>
                  <DialogTitle>Зургийн сан</DialogTitle>
                  <DialogContent
                    sx={{
                      py: 1,
                    }}
                  >
                    <ImageSelector handleSelectImage={handleSelectImage} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageSelect(false)}>Цуцлах</Button>
                  </DialogActions>
                </Dialog>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Брэнд ковер зураг
                  </div>
                  <Box
                    {...coverDropzone.getRootProps()}
                    sx={{ border: '1px dashed', padding: '20px', textAlign: 'center' }}
                  >
                    <input {...coverDropzone.getInputProps()} />

                    {brandDetailCoverImg.length > 0 ? (
                      ' '
                    ) : (
                      <p>Drag 'n' drop some files here, or click to select cover image</p>
                    )}
                    {brandDetailCoverImg.length > 0 &&
                      brandDetailCoverImg.map((file, index) => {
                        console.log(brandDetailCoverImg);

                        return (
                          <div key={index} style={{ marginBottom: '10px' }}>
                            <img
                              src={typeof file === 'string' ? API_URL+file : URL.createObjectURL(file)}
                              alt={`Cover ${index + 1}`}
                              width="full"
                              height="100"
                            />
                            <Button variant="contained" color="secondary" onClick={() => handleDeleteCover(index)}>
                              Устгах
                            </Button>
                          </div>
                        );
                      })}
                  </Box>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Брэнд лого
                  </div>
                  <Box
                    {...logoDropzone.getRootProps()}
                    sx={{ border: '1px dashed', padding: '20px', textAlign: 'center' }}
                  >
                    <input {...logoDropzone.getInputProps()} />
                    {brandLogo.length > 0 ? '' : <p>Drag 'n' drop some files here, or click to select logo</p>}
                    {brandLogo.length > 0 &&
                      brandLogo.map((file, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <img
                            src={typeof file === 'string' ? API_URL+file : URL.createObjectURL(file)}
                            alt={`Logo ${index + 1}`}
                            width="100"
                            height="100"
                          />
                          <Button variant="contained" color="secondary" onClick={() => handleDeleteLogo(index)}>
                            Устгах
                          </Button>
                        </div>
                      ))}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
