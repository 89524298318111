/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Grid,
  Popover,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  IconButton,
  Chip,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
// import { DivNew, DivUpdate, DivDelete } from '../sections/@dashboard/division';
import { FaqCreate, FaqUpdate, FaqDelete, FaqActive, TabNew, TabUpdate, TabDelete } from '../sections/@dashboard/faq';
// api
import API from '../api';

// ----------------------------------------------------------------------

export default function FaqPage() {
  const [open, setOpen] = useState(null);
  const [openSub, setOpenSub] = useState(null);

  const [selectedDiv, setSelectedDiv] = useState(null);
  const [selectedTab, setSelecTab] = useState(null);

  const [divs, setDivs] = useState([]);
  const [tabs, setSubTabs] = useState([]);

  const [page, setPage] = useState(1);

  const fetchDivs = async () => {
    const response = await API.get(`/feedback/question`);
    const {
      data: { data, success },
    } = response;
    if (success === 1) setDivs(data);
  };
  const fetchTabsByDivId = async (id) => {
    const response = await API.get(`/feedback/answer/${id}`);
    const {
      data: { data },
    } = response;
    setSubTabs(data);
  };

  useEffect(() => {
    try {
      fetchDivs();
    } catch (error) {
      console.warn(error.message);
    }
  }, []);

  useEffect(() => {
    fetchDivs();
    if (selectedDiv?.f_q_id) fetchTabsByDivId(selectedDiv.f_q_id);
  }, [page]);

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenSub(null);
  };

  const handleOpenDiv = (event, data) => {
    setSelectedDiv(data);
    setOpen(event.currentTarget);
  };

  const handleOpenTab = (event, data) => {
    setSelecTab(data);
    setOpenSub(event.currentTarget);
  };

  const resetAll = () => {
    handleCloseMenu();
    setPage(1);
    fetchDivs({});
    if (selectedDiv?.f_q_id) fetchTabsByDivId(selectedDiv.f_q_id);
  };

  return (
    <>
      <Helmet>
        <title>Үнэлгээ</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Үнэлгээ
          </Typography>
        </Stack>

        <Scrollbar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Асуултууд</TableCell>
                        <TableCell>Төлөв</TableCell>
                        <TableCell align="right">
                          <FaqCreate resetPage={resetAll} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {divs.length > 0 ? (
                        divs.map((rowData) => {
                          const { f_q_id, f_q_name, isActive } = rowData;
                          return (
                            <TableRow
                              key={f_q_id}
                              hover
                              onClick={() => {
                                fetchTabsByDivId(f_q_id);
                                setSelectedDiv(rowData);
                              }}
                            >
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {f_q_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                {isActive === 0 && <Chip color="info" label="Pending" />}
                                {isActive === 1 && <Chip color="success" label="Active" />}
                                {isActive === 2 && <Chip label="Archive" />}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenDiv(event, rowData)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Өгөгдөл олдсонгүй
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Хариултууд</TableCell>
                        <TableCell>Тоо</TableCell>
                        <TableCell align="right">
                          {selectedDiv?.isActive === 0 && <TabNew resetAll={resetAll} id={selectedDiv.f_q_id} />}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tabs?.length > 0 ? (
                        tabs?.map((rowData) => {
                          const { f_id, f_name, f_count } = rowData;
                          return (
                            <TableRow key={f_id} hover>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {f_name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap px={2}>
                                    {f_count}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="right">
                                {selectedDiv?.isActive === 0 ? (
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(event) => handleOpenTab(event, rowData)}
                                  >
                                    <Iconify icon={'eva:more-vertical-fill'} />
                                  </IconButton>
                                ) : (
                                  <span>{''}</span>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                {selectedDiv ? 'Өгөгдөл олдсонгүй' : 'Асуулт сонгоно уу'}
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {selectedDiv?.isActive === 0 && (
          <MenuItem>
            <FaqUpdate selectedQuestion={selectedDiv} resetAll={resetAll} />
          </MenuItem>
        )}
        {selectedDiv?.isActive === 0 && (
          <MenuItem>
            <FaqActive id={selectedDiv?.f_q_id} resetAll={resetAll} />
          </MenuItem>
        )}

        <MenuItem sx={{ color: 'error.main' }}>
          <FaqDelete id={selectedDiv?.f_q_id} resetAll={resetAll} />
        </MenuItem>
      </Popover>

      <Popover
        open={Boolean(openSub)}
        anchorEl={openSub}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <TabUpdate tab={selectedTab} resetAll={resetAll} />
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <TabDelete id={selectedTab?.f_id} resetAll={resetAll} />
        </MenuItem>
      </Popover>
    </>
  );
}
