/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Grid,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { ImageSelector } from './ProductModal';
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsUpdate({ news, resetPage, categories, brands }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [brandDetailDesc, setBrandDetailDesc] = useState('');
  const [brandDetailEmail, setBrandDetailEmail] = useState('');
  const [brandDetailFacebook, setBrandDetailFacebook] = useState('');
  const [catName, setCatName] = useState('');
  const [brandDetailNumber, setBrandDetailNumber] = useState('');
  const [brandName, setBrandName] = useState('');
  const [web_url, setWeb_url] = useState('');
  const [selectedCategory, setsetselectedCategory] = useState('');

  const [brandDetailCoverImg, setBrandDetailCoverImg] = useState([]);
  const [brandLogo, setBrandLogo] = useState([]);
  const [paths, setPaths] = useState([]);
  const [logoPaths, setLogoPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageSelect, setOpenImageSelect] = useState(false);

  const [productOpenStyle, setproductOpenStyle] = useState('');
  const [productImage, setProductImage] = useState([]);
  const [catId, setCatId] = useState('');
  const [productMaterial, setProductMaterial] = useState('');
  const [user_id, setUser_id] = useState('');
  const [productColor, setProductColor] = useState('');
  const [productUsage, setProductUsage] = useState('');
  const [productStyle, setProductStyle] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [deletedProductImage, setDeletedProductImage] = useState([]);

  useEffect(() => {
    const {
      _id,
      productOpenStyle,
      productImage,
      catId,
      productMaterial,
      user_id,
      productColor,
      productUsage,
      productStyle,
      productBrandId,
    } = news;

    setproductOpenStyle(productOpenStyle);
    setProductImage(productImage);
    setCatId(catId);
    setProductMaterial(productMaterial);
    setUser_id(user_id);
    setsetselectedCategory(catId?._id || '');
    setProductColor(productColor);
    setProductUsage(productUsage);
    setProductStyle(productStyle);
    setSelectedBrand(productBrandId || '');
    console.log(news);
    console.log(brands);

  }, [news, categories]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });
  const {
    acceptedFiles: acceptedLogoFiles,
    getRootProps: getLogoRootProps,
    getInputProps: getLogoInputProps,
  } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });
  const handleCoverDrop = (acceptedFiles) => {
    setBrandDetailCoverImg(acceptedFiles);
  };

  const handleLogoDrop = (acceptedFiles) => {
    setBrandLogo(acceptedFiles);
  };
  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const logoFiles = acceptedLogoFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const PDFFiles = acceptedLogoFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setproductOpenStyle('');
    setProductImage([]);
    setBrandDetailCoverImg([])
    setCatId('');
    setProductMaterial('');
    setUser_id('');
    setsetselectedCategory('');
    setProductColor('');
    setProductUsage('');
    setProductStyle('');
    setSelectedBrand('');
    setDeletedProductImage([]);
    setsetselectedCategory(''); // Accessing _id safely
  };

  const coverDropzone = useDropzone({
    accept: 'image/*',
    maxFiles: 10,
    onDrop: handleCoverDrop,
  });

  const logoDropzone = useDropzone({
    accept: 'image/*',
    maxFiles: 10,
    onDrop: handleLogoDrop,
  });

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();
    brandDetailCoverImg.forEach((image) => {
      newsData.append('images', image);
    });

    newsData.append('productOpenStyle', productOpenStyle);
    deletedProductImage.forEach((item) => {
      newsData.append('deletedProductImage', item);
    });
    newsData.append('catId', selectedCategory);
    newsData.append('productMaterial', productMaterial);
    newsData.append('productColor', productColor);
    newsData.append('productUsage', productUsage);
    newsData.append('productBrandId', selectedBrand);

    try {
      setLoading(true);
      const response = await API.put(`/v2/product/${news._id}`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSelectImage = (data) => {
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };
  const handlePickImage = () => {
    setOpenImageSelect(true);
  };
  const handleDeleteCover = (index) => {
    const deletedImage = productImage[index];
    const updatedCoverImg = [...productImage];
    updatedCoverImg.splice(index, 1);
    setProductImage(updatedCoverImg);
    const tempArray = deletedProductImage;
    tempArray.push(deletedImage);
    setDeletedProductImage(tempArray);
    console.log(deletedProductImage);
  };
  const handleDeleteLogo = (index) => {
    const updatedLogo = [...brandDetailCoverImg];
    updatedLogo.splice(index, 1);
    setBrandDetailCoverImg(updatedLogo);
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Бүтээгдэхүүн засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={productMaterial}
                onChange={(event) => setProductMaterial(event.target.value)}
                label="Материал"
                type="text"
                fullWidth
                autoFocus
              />
              <FormControl fullWidth sx={{ width: 1, my: 1 }}>
                <InputLabel id="category-box-label">Brand</InputLabel>
                <Select
                  labelId="category-box-label"
                  id="category-box"
                  defaultValue={selectedBrand._id}
                  label="Brand сонгох"
                >
                  {brands.map((option, index) => (
                    <MenuItem
                      onClick={(event) => {
                        setSelectedBrand(option._id);
                      }}
                      key={index}
                      value={option._id}
                    >
                      {option.brandName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: 1, my: 1 }}>
                <InputLabel id="category-box-label">Категори сонгох</InputLabel>
                <Select
                  labelId="category-box-label"
                  id="category-box"
                  defaultValue={selectedCategory}
                  label="Категори сонгох"
                >
                  {categories.map((option, index) => (
                    <MenuItem
                      onClick={(event) => {
                        setsetselectedCategory(option._id);
                      }}
                      key={index}
                      value={option._id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={productColor}
                onChange={(event) => setProductColor(event.target.value)}
                label="Өнгө"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={productOpenStyle}
                onChange={(event) => setproductOpenStyle(event.target.value)}
                label="Openstyle"
                type="text"
                fullWidth
                autoFocus
              />{' '}
              <TextField
                sx={{
                  mb: 1,
                }}
                value={productStyle}
                onChange={(event) => setProductStyle(event.target.value)}
                label="Style"
                type="text"
                fullWidth
                autoFocus
              />{' '}
              <TextField
                sx={{
                  mb: 1,
                }}
                value={productUsage}
                onChange={(event) => setProductUsage(event.target.value)}
                label="Дэлгэрэнгүй"
                type="text"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Dialog open={openImageSelect} fullWidth maxWidth={false}>
                  <DialogTitle>Зургийн сан</DialogTitle>
                  <DialogContent
                    sx={{
                      py: 1,
                    }}
                  >
                    <ImageSelector handleSelectImage={handleSelectImage} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageSelect(false)}>Цуцлах</Button>
                  </DialogActions>
                </Dialog>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Брэнд ковер зураг
                  </div>
                  <Box
                    {...coverDropzone.getRootProps()}
                    sx={{ border: '1px dashed', padding: '20px', textAlign: 'center' }}
                  >
                    <input {...coverDropzone.getInputProps()} />

                    <p>Drag 'n' drop some files here, or click to select cover image</p>

                    {productImage
                      ? productImage.length > 0 &&
                        productImage.map((file, index) => {
                          console.log(productImage);

                          return (
                            <div key={index} style={{ marginBottom: '10px' }}>
                              <img
                                src={typeof file === 'string' ? API_URL + file : URL.createObjectURL(file)}
                                alt={`Cover ${index + 1}`}
                                width="full"
                                height="100"
                              />
                              <Button variant="contained" color="secondary" onClick={() => handleDeleteCover(index)}>
                                Устгах
                              </Button>
                            </div>
                          );
                        })
                      : ''}
                    {brandDetailCoverImg.length > 0 &&
                      brandDetailCoverImg.map((file, index) => {
                        console.log(brandDetailCoverImg);

                        return (
                          <div key={index} style={{ marginBottom: '10px' }}>
                            <img
                              src={typeof file === 'string' ? API_URL + file : URL.createObjectURL(file)}
                              alt={`Cover ${index + 1}`}
                              width="full"
                              height="100"
                            />
                            <Button variant="contained" color="secondary" onClick={() => handleDeleteLogo(index)}>
                              Устгах
                            </Button>
                          </div>
                        );
                      })}
                  </Box>
                 
                 
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
