/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { alpha } from '@mui/material/styles';
import { MenuItem, IconButton, Popover } from '@mui/material';
import axios from 'axios';

// @components

import { EXPORT_API_URL } from '../../../api';
import UserProfile from '../../../sections/@dashboard/user/UserProfile';

// const API_URL = {
//   dev: `http://192.168.1.106:8005/api`,
//   prod: 'http://178.128.115.250/api',
// };

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const checkUser = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: { Authorization: `Token ${accessToken}` },
      };
      const { data } = await axios.get(`${EXPORT_API_URL}/v2/auth/me`, config);
      console.log(data)
      if (data?.success !== true) {
        throw new Error();
      } else {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('policeDisplayName', data.data.username || 'admin');
        return;
      }
    } catch (err) {
      console.error(err.message);
      toast.error('Нэвтэрнэ үү.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      handleClose();
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  const handleClose = () => {
    setOpen(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('role');
    localStorage.removeItem('policeDisplayName');
    // navigate('/login');
    window.location.href = '/login';
  };

  const handleReset = () => {
    navigate('/reset-password');
  };

  const handleEditProfile = () => {
    // navigate('/profile');
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <div
          style={{
            width: 40,
            height: 40,
            display: 'flex',
            justifyItems: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ebebeb',
            borderRadius: '50%',
          }}
        >
          A
        </div>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 220,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
     
        <MenuItem onClick={handleReset} sx={{ m: 1 }}>
          Нууц үг солих
        </MenuItem>
        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Гарах
        </MenuItem>
      </Popover>
    </>
  );
}
