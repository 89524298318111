/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Avatar } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function TeamUpdate({ resetPage, selectedPerson }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data , setData] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const personData = new FormData();

    const isChanged = acceptedFiles[0] !== {} && acceptedFiles[0] !== undefined && acceptedFiles[0] !== null;
   
    personData.append('img', isChanged ? acceptedFiles[0] : selectedPerson.img);
   
    try {
      setLoading(true);
      const response = await API.put(`/v2/banner/${selectedPerson._id}`, personData, config);
      const {
        data: { success, message },
      } = response;
      if (success) {
        resetPage();
      
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Баннер засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
        
          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
              <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
            </div>
            {imageFiles.length > 0 && (
              <aside>
                <h4>Зураг</h4>
                <p>{imageFiles}</p>
              </aside>
            )}
            {data.img && data.length === 0 && (
              <div>
                <Box sx={{ borderRadius: 1, border: 'solid', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                  <Avatar
                    sx={{ bgcolor: '#ebebeb' }}
                    alt="img"
                    src={`${API_URL}${data.img}`}
                    variant="square"
                  />
                </Box>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
