/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FeedbackCreate({ resetPage }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [answer, setAnswer] = useState('');

  const [role, setRole] = useState(1);
  const [username, setUsername] = useState();
  const [company, setCompany] = useState();
  const [password, setPassword] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const userData = new FormData();
      userData.append('username', username);
      userData.append('role', role);
      userData.append('company', company);
      userData.append('password', password);
      const {
        data: { message, success },
      } = await API.post(`/auth/user`, userData, config);
      if (success) {
        resetPage();
        setRole(1);
        setUsername('');
        setCompany('');
        setPassword('');
        handleClose();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} fullWidth maxWidth={'lg'} onClose={handleClose}>
        <DialogTitle>Хэрэглэгч нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid item xs={12}>
            {' '}
            <TextField
              sx={{
                mt: 1,
              }}
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              label="Компани"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {' '}
            <TextField
              sx={{
                mt: 1,
              }}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              label="Username"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {' '}
            <TextField
              sx={{
                mt: 1,
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              label="Нууц үг"
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            {' '}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Хэрэглэгчийн төрөл</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Хэрэглэгчийн төрөл"
                onChange={(event) => setRole(event.target.value)}
              >
                <MenuItem value={1}>Админ</MenuItem>
                <MenuItem value={2}>Хэрэглэгч</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
