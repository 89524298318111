/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-extra-boolean-cast */
import axios from 'axios';

const BASE_URL = {
  dev: 'http://192.168.8.100:8002',
  prod: 'https://mmmall.mn',
};

const BASE_API_URL = {
  dev: `http://192.168.8.100:8002/api`,
  prod: 'https://mmmall.mn/api',
};

export const API_URL = BASE_URL.prod;
export const EXPORT_API_URL = BASE_API_URL.prod;

let customAxios = axios.create({
  baseURL: EXPORT_API_URL,
});

const handleLocalStorage = () => {
  window.dispatchEvent(new Event('storage'));
};

window.addEventListener('storage', () => {
  const accessToken = localStorage.getItem('accessToken');
  customAxios = axios.create({
    baseURL: EXPORT_API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
});
handleLocalStorage();

customAxios.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    const token = localStorage.getItem('accessToken');
    error.config.headers.common = { Authorization: `Bearer ${token}` };
    return axios.request(error.config);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default customAxios;
