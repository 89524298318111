/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

const filter = createFilterOptions();

// legacy: used before
// const ROLES = [
//   { label: 'Super admin', value: 1 },
//   { label: 'Admin', value: 2 },
// ];

export default function AdminUpdate({ resetPage, selectedAdmin, divisions }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState();
  const [username, setUsername] = useState();
  const [company, setCompany] = useState();

  const [selectedDiv, setSelectedDiv] = useState(null);

  useEffect(() => {
    const { username, company, role } = selectedAdmin;
    setUsername(username);
    setCompany(company);
    setRole(role);
    console.log(selectedAdmin)
  }, [selectedAdmin]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { success, message },
      } = await API.put(`/auth/user/${selectedAdmin._id}`, {
        username: username || selectedAdmin.username,
        role,
        company,
      });
      if (success) {
        resetPage();
        handleClose();
        toast.success('Амжилттай шинэчлэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog fullWidth maxWidth={'lg'} open={open} onClose={handleClose}>
        <DialogTitle>Засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{
                  mt: 1,
                }}
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                label="Username"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{
                  mt: 1,
                }}
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                label="Компани"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Хэрэглэгчийн төрөл</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Хэрэглэгчийн төрөл"
                  onChange={(event) => setRole(event.target.value)}
                >
                  <MenuItem value={1}>Админ</MenuItem>
                  <MenuItem value={2}>Хэрэглэгч</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
