/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function TeamNew({ resetPage }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [position, setPosition] = useState('');
  const [content, setContent] = useState('');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, _progress) =>
    new Promise((resolve, _reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const personData = new FormData();

    personData.append('man_img', acceptedFiles[0]);
    personData.append('man_firstName', firstName);
    personData.append('man_lastName', lastName);
    personData.append('man_position', position);
    personData.append('man_about', content);

    try {
      setLoading(true);
      const response = await API.post(`/team`, personData, config);
      const {
        data: { message, success },
      } = response;
      if (success === 1) {
        resetPage();
        setFirstName('');
        setLastName('');
        setPosition('');
        setContent('');

        handleClose();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Хүн нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            label="Овог"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            label="Нэр"
            type="text"
            fullWidth
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={position}
            onChange={(event) => setPosition(event.target.value)}
            label="Албан тушаал"
            type="text"
            fullWidth
          />

          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
              <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
            </div>
            {imageFiles.length > 0 && (
              <aside>
                <h4>Зураг</h4>
                <p>{imageFiles}</p>
              </aside>
            )}
          </Box>
          <Box mt={4} color="black">
            Намтар
          </Box>
          <Editor
            className="z-[9999]"
            apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
            onInit={(_evt, editor) => {
              setContent(editor.getContent());
              // console.log(editor)

              const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
              editor.editorContainer.appendChild(tinyAuxEl);
            }}
            value={content}
            onEditorChange={(curContent, _editor) => {
              setContent(curContent);
            }}
            init={{
              height: 500,
              menubar: false,
              readonly: false,
              plugins: `preview importcss searchreplace autolink autosave save 
                    directionality code visualblocks visualchars fullscreen 
                    image link media template codesample table charmap 
                    pagebreak nonbreaking anchor advlist 
                    lists wordcount help charmap emoticons table code`,
              toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                    | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                    | outdent indent | numlist bullist 
                    | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                    |  save print table code
                    | insertfile image media template link anchor codesample | ltr rtl`,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              file_picker_types: 'file image media',
              images_upload_handler: upImg,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
