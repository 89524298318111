import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar, Autocomplete, TextField, Button, IconButton, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------
import Iconify from '../../../components/iconify';

import API from '../../../api';

// eslint-disable-next-line no-unused-vars
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

CrimeListToolbar.propTypes = {
  onSelectCategory: PropTypes.func,
  filterSearch: PropTypes.func,
  categories: PropTypes.array,
  resetPage: PropTypes.func,
};

export default function CrimeListToolbar({ categories, filterSearch, resetPage }) {
  const [subCategories, setSubCategories] = useState([]);
  const [users, setUsers] = useState([]);

  const [title, setTitle] = useState('');
  const [newsId, setNewsId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(dayjs());

  const onSelectCategory = (data) => {
    console.log(data)
    setSelectedCategory(data);
  };

  const fetchUsers = async () => {
    const response = await API.get('/auth');
    const {
      data: { data },
    } = response;
    setUsers(data);
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const onSelectSubCategory = (data) => {
    setSelectedSubCategory(data);
  };

  const handleClick = () => {
    filterSearch({
      selectedCategory,
    });
  };

  const handleClear = () => {
    setTitle('');
    setNewsId('');
    setSelectedUser(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setStartDate(undefined);
    setEndDate(dayjs());
    setSubCategories([]);
    resetPage();
  };

  return (
    <>
      <Grid container spacing={1} p={2}>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            disableClearable
            id="category-box"
            isOptionEqualToValue={(option, value) => option.cat_id === value.cat_id}
            getOptionLabel={(option) => option.name}
            options={categories || []}
            value={selectedCategory || null}
            onChange={(_event, value) => onSelectCategory(value)}
            renderInput={(params) => <TextField {...params} label="Категори сонгох" />}
            sx={{
              width: '100%',
            }}
          />
        </Grid>
      
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            startIcon={<Iconify icon="ic:baseline-search" />}
            onClick={() => handleClick()}
          >
            Хайх
          </Button>

          <IconButton size="large" onClick={() => handleClear()} sx={{ ml: 1 }}>
            <Iconify icon="material-symbols:delete" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
