import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Popover,
  Avatar,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  IconButton,
  CircularProgress,
  Grid,
  Box,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { Editor } from '@tinymce/tinymce-react';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import AboutUpdate from '../sections/@dashboard/about/AboutUpdate';
// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

const ABOUT_ITEMS = [
  {
    id: 'greeting',
    name: 'Мэндчилгээ',
  },
  {
    id: 'structure',
    name: 'Бүтэц',
  },
  {
    id: 'mission',
    name: 'Эрхэм зорилго',
  },
];

export default function AboutPage() {
  const [open, setOpen] = useState(null);
  const [content, setContent] = useState('');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [selectedItem, setSelectedItem] = useState({});

  const fetchContents = async () => {
    const response = await API.get('/about');
    const {
      data: { data, success, message },
    } = response;
    if (success) {
      setData(data);
      setContent(data.text);
      console.log(data);
    } else {
      toast.error(`Хүсэлт амжилтгүй ${message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });
  useEffect(() => {
    try {
      setLoading(true);
      fetchContents();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const personData = new FormData();

    const isChanged = acceptedFiles[0] !== {} && acceptedFiles[0] !== undefined && acceptedFiles[0] !== null;

    personData.append('cover', isChanged ? acceptedFiles[0] : data.cover);
    personData.append('text', content);

    try {
      setLoading(true);
      const response = await API.post(`/about`, personData, config);
      const {
        data: { success, message },
      } = response;
      if (success) {
        resetPage();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenMenu = (event, data) => {
    setSelectedItem(data);
    setOpen(event.currentTarget);
  };
  const upImg = (blobInfo, _progress) =>
    new Promise((resolve, _reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const resetPage = () => {
    setOpen(null);
    setSelectedItem({});
    fetchContents();
  };

  return (
    <>
      <Helmet>
        <title> Бидний тухай</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Бидний тухай
          </Typography>
        </Stack>

        <Scrollbar>
          <Card>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
            <Typography my={2} fontWeight={700} mx={4}>
              Нүүр зураг
            </Typography>
            <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 4, m: 4, textAlign: 'center' }}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
              </div>
              {imageFiles.length > 0 && (
                <aside>
                  <h4>Зураг</h4>
                  <p>{imageFiles}</p>
                </aside>
              )}
              {data && imageFiles.length === 0 && (
                <div>
                  <Box sx={{ borderRadius: 1, border: 'solid', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <Avatar sx={{ bgcolor: '#ebebeb' }} alt="img" src={`${API_URL}${data.cover}`} variant="square" />
                  </Box>
                </div>
              )}
            </Box>
            <Typography my={2} fontWeight={700} mx={4}>
              Агуулга
            </Typography>
            <Box m={4}>
              <Editor
                className="z-[9999]"
                apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
                onInit={(_evt, editor) => {
                  setContent(editor.getContent());

                  const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
                  editor.editorContainer.appendChild(tinyAuxEl);
                }}
                value={content}
                onEditorChange={(curContent, _editor) => {
                  setContent(curContent);
                }}
                init={{
                  height: 500,
                  menubar: false,
                  readonly: false,
                  plugins: `preview importcss searchreplace autolink autosave save 
                    directionality code visualblocks visualchars fullscreen 
                    image link media template codesample table charmap 
                    pagebreak nonbreaking anchor advlist 
                    lists wordcount help charmap emoticons table code`,
                  toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                    | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                    | outdent indent | numlist bullist 
                    | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                    |  save print table code
                    | insertfile image media template link anchor codesample | ltr rtl`,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  file_picker_types: 'file image media',
                  images_upload_handler: upImg,
                }}
              />
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item px={4} pb={2}>
                <Button onClick={handleSubmit} variant="contained" startIcon={<Iconify icon="bx:save" />}>
                  Хадгалах
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <AboutUpdate selectedItem={selectedItem} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
