/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FeedbackUpdate({ resetPage, selectedFeedback }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    const { f_question, f_answer } = selectedFeedback;
    setName(f_question);
    setAnswer(f_answer);
  }, [selectedFeedback]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { success, message },
      } = await API.put(`/faq`, {
        f_id: selectedFeedback.f_id,
        f_question: name,
        f_answer: answer,
      });

      if (success === 1) {
        resetPage();
        setName('');
        setAnswer('');
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Үнэлгээ засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Асуулт"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={answer}
            onChange={(event) => setAnswer(event.target.value)}
            label="Хариулт"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
