import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
import DashboardLayout from './layouts/dashboard';
//
import FaqPage from './pages/FaqPage';
import Page404 from './pages/Page404';
import NewsPage from './pages/NewsPage';
import TeamPage from './pages/TeamPage';
import CrimePage from './pages/CrimePage';
import LoginPage from './pages/LoginPage';
import ResetPage from './pages/ResetPage';
import FeedbackPage from './pages/FeedbackPage';
import CategoryPage from './pages/CategoryPage';
import ReportTypePage from './pages/ReportTypePage';
import QuestionKeyPage from './pages/QuestionKeyPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SubCrimeTypePage from './pages/SubCrimeTypePage';
import ReportPage from './pages/ReportPage';
import CrimeTypePage from './pages/CrimeTypePage';
import BannerPage from './pages/BannerPage';
import DivPage from './pages/DivPage';
import AdminDivPage from './pages/AdminDivPage';
import AdminPage from './pages/AdminPage';
import ImagePage from './pages/ImagePage';
import FilePage from './pages/FilePage';
import AboutPage from './pages/AboutPage';
import CustomBannerPage from './pages/CustomBannerPage';
import NewsBannerPage from './pages/NewsBannerPage';
import StatisticPage from './pages/StatisticPage';
import UsersPage from './pages/DepartmentPage';
import ProductPage from './pages/ProductPage';
import PricePage from './pages/Price';
import ShiidelPage from './pages/Mshiidel';
import CreatedByPage from './pages/CreatedBy';
import MrMisheelPage from './pages/mrmisheel';
import CoLeaderPage from './pages/CoLeaderPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/brand" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'categories', element: <CategoryPage /> },
        { path: 'banner', element: <BannerPage /> },
        { path: 'custom-banner', element: <CustomBannerPage /> },
        { path: 'news-banner', element: <NewsBannerPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'crime-reports', element: <CrimePage /> },
        { path: 'brand', element: <NewsPage /> },
        { path: 'product', element: <ProductPage /> },
        { path: 'createdby', element: <CreatedByPage /> },
        { path: 'mrmisheel', element: <MrMisheelPage /> },
        { path: 'price', element: <PricePage /> },
        { path: 'mshiidel', element: <ShiidelPage /> },
        { path: 'coleader', element: <CoLeaderPage /> },
        { path: 'statistic', element: <StatisticPage /> },
        { path: 'team', element: <TeamPage /> },
        { path: 'feedback', element: <FeedbackPage /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'keys', element: <QuestionKeyPage /> },
        { path: 'report-types', element: <ReportTypePage /> },
        { path: 'user', element: <UsersPage /> },
        { path: 'sub-crime-types', element: <SubCrimeTypePage /> },
        { path: 'crimes', element: <ReportPage /> },
        { path: 'crime-types', element: <CrimeTypePage /> },
        { path: 'division', element: <DivPage /> },
        { path: 'admin-division', element: <AdminDivPage /> },
        { path: 'admin', element: <AdminPage /> },
        { path: 'images', element: <ImagePage /> },
        { path: 'files', element: <FilePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/brand" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
