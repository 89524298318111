/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FaqActive({ resetAll, id }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.post(`/feedback/activate`, {
        f_q_id: id,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetAll();
        handleClose();
        toast.success('Амжилттай Баталгаажууллаа', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Баталгаажуулах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Асуулт баталгаажуулах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <h4>Одоогийн идэвхтэй асуулт хариулт архивлагдах болно.</h4>
          Баталгаажуулахад итгэлтэй байна уу?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? (
            'Уншиж байна...'
          ) : (
            <Button onClick={handleSubmit} color="error">
              Баталгаажуулах
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
