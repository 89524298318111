import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// service
import API from '../../../api';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = async () => {
    if (!username) return;
    if (!password) return;

    try {
      setLoading(true);
      const response = await API.post('/v2/auth/login', {
        username,
        password,
      });
      const {
        data: { success, message, accessToken, username: policeDisplayName, role },
      } = response;

      if (success === true) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('role', role);
        navigate('/dashboard', { replace: true });
      } else {
        toast.error(`Хэрэглэгчийн нэр эсвэл нууц үг буруу байна. ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="username"
          label="User Name"
          value={username}
          onChange={(event) => setUserName(event.target.value)}
          onKeyDown={handleKeyDown}
        />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyDown}
        />
      </Stack>
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link href="/forgot" variant="subtitle2" underline="hover">
          Нууц үг мартсан?
        </Link>
      </Stack> */}
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <LoadingButton
          sx={{
            mt: 3,
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
        >
          Login
        </LoadingButton>
      )}
    </>
  );
}
