// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const userNavConfig = [
  // {
  //   title: 'Самбар',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Бүтээгдэхүүн',
    path: '/dashboard/product',
    icon: icon('ic_lock'),
  },
  {
    title: 'Brand',
    path: '/dashboard/brand',
    icon: icon('ic_blog'),
  },
];

export default userNavConfig;
