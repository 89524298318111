/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';
import API from '../../../api';

export default function TabUpdate({ resetAll, tab }) {
  const { tab_name, tab_detail, d_id, tab_id } = tab;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [content, setContent] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setName(tab_name);
    setContent(tab_detail);
  }, [tab]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.put(`/division/tab`, {
        d_id,
        tab_id,
        tab_name: name,
        tab_detail: content,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        setName('');
        handleClose();
        resetAll();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        // do what you wanna do
        // console.log(res); // res is base64 now
        resolve(res);
      });
    });

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Ангилал засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Нэр"
            type="text"
            fullWidth
            autoFocus
          />
          <Editor
            apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
            onInit={(_evt, editor) => {
              setContent(editor.getContent());

              const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
              editor.editorContainer.appendChild(tinyAuxEl);
            }}
            value={content}
            onEditorChange={(curContent, _editor) => setContent(curContent)}
            init={{
              height: 500,
              menubar: false,
              plugins: `preview importcss searchreplace autolink autosave save 
                  directionality code visualblocks visualchars fullscreen 
                  image link media template codesample table charmap 
                  pagebreak nonbreaking anchor advlist 
                  lists wordcount help charmap emoticons table code`,
              toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                  | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                  | outdent indent | numlist bullist 
                  | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                  |  save print table code
                  | insertfile image media template link anchor codesample | ltr rtl`,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              file_picker_types: 'file image media',
              images_upload_handler: upImg,
            }}
          />
          {/* <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
              clipboard: {
                matchVisual: false,
              },
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'video',
            ]}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={() => handleSubmit()}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
