/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Card,
  CircularProgress,
  Avatar,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
// components
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsModal({ resetPage, categories, brands }) {
  const [open, setOpen] = useState(false);
  const [openImageSelect, setOpenImageSelect] = useState(false);
  const [loading, setLoading] = useState(false);

  const [brandDetailCoverImg, setBrandDetailCoverImg] = useState([]);

  const [paths, setPaths] = useState([]);

  const [priceDate, setPriceDate] = useState('');
  const [priceText, setPriceText] = useState('');
  const [priceTitle, setPriceTitle] = useState('');
  const [productColor, setProductColor] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
    },
    [setPaths]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop,
  });

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const handleCoverDrop = (acceptedFiles) => {
    setBrandDetailCoverImg(acceptedFiles);
  };
  const coverDropzone = useDropzone({
    accept: 'image/*',
    maxFiles: 10,
    onDrop: handleCoverDrop,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteCover = (index) => {
    const updatedLogo = [...brandDetailCoverImg];
    updatedLogo.splice(index, 1);
    setBrandDetailCoverImg(updatedLogo);
  };
  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    acceptedPDFFiles.length = 0;
    acceptedPDFFiles.splice(0, acceptedFiles.length);
  };
  const handleClose = () => {
    removeAll();
    setOpen(false);
    setSelectedImage(null);
    setPaths([]);
  };

  const showError = (field) => {
    toast.error(`${field}  оруулна уу!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();
    brandDetailCoverImg.forEach((image) => {
      newsData.append('images', image);
    });

    newsData.append('icon', acceptedPDFFiles[0] || null);
    newsData.append('txt', priceText);
    newsData.append('url', priceTitle);

    try {
      setLoading(true);
      const response = await API.post(`/mrmisheel`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  const handleDateChange = (newValue) => {
    // Format the selected date using dayjs
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
    setPriceDate(formattedDate);
    console.log(priceDate);
  };
  const handleSelectImage = (data) => {
    // console.log(data);
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };

  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Mr.Misheel нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={priceTitle}
                onChange={(event) => setPriceTitle(event.target.value)}
                label="Url"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                multiline
                sx={{
                  mb: 1,
                }}
                value={priceText}
                onChange={(event) => setPriceText(event.target.value)}
                label="Дэлгэрэнгүй"
                type="text"
                fullWidth
                autoFocus
              />
           
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Dialog open={openImageSelect} fullWidth maxWidth={false}>
                  <DialogTitle>Зургийн сан</DialogTitle>
                  <DialogContent
                    sx={{
                      py: 1,
                    }}
                  >
                    <ImageSelector handleSelectImage={handleSelectImage} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageSelect(false)}>Цуцлах</Button>
                  </DialogActions>
                </Dialog>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Icon
                  </div>
                  <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <div {...getPDFRootProps({ className: 'dropzone' })}>
                      <input {...getPDFInputProps()} />
                      <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                      <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      <em>(Дахин дарж солино уу.)</em>
                    </div>
                    {PDFFiles.length > 0 && (
                      <aside>
                        <h4>Зураг:</h4>
                        <p>{PDFFiles}</p>
                      </aside>
                    )}
                  </Box>
                 
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ImageSelector = ({ handleSelectImage }) => {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [images, setImages] = useState([]);

  const fetchImages = async (page = 1, limit = 20) => {
    const response = await API.get(`/upload/search?page=${page}&limit=${limit}&text=&file_type=image`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setImages(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchImages(page, rowsPerPage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const handleSelectImage = (data) => {
  //   console.log(data);
  //   setSelectedImage(data);
  // };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress />}

        <Grid container alignItems="center" justifyContent="center" m={5} p={5} gap={1}>
          {images && images.length > 0 ? (
            <>
              {images.map((imageData, key) => {
                const { file_path, file_id } = imageData;
                return (
                  <Grid
                    key={key}
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ebebeb',
                      ':hover': {
                        cursor: 'pointer',
                        opacity: 80,
                        bg: '#ebebeb',
                      },
                    }}
                    p={1}
                    onClick={() => handleSelectImage(imageData)}
                  >
                    <Avatar
                      sx={{ bgcolor: '#ebebeb', width: '100px', height: '100px' }}
                      alt="img"
                      src={`${API_URL}${file_path}`}
                      variant="square"
                    />
                    <Box sx={{ textAlign: 'center', fontSize: '12px' }}>{file_path.split('/').pop()}</Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
              No Images
            </Grid>
          )}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};
